@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    background: transparent;
}

body {
    overflow: hidden;
    overflow-y: scroll;
}

.brand-color {
    color: #60A5FA;
}

.hover-brand-color:hover {
    color: #3e8eef;
}

.bg-brand-color {
    background-color: #60A5FA;
}

.border-brand-color {
    border: 1px solid #60A5FA;
}

.about {
    background-image: linear-gradient(to left bottom, #f87171, #f55979, #ee4085, #e12896, #cb18a9, #bd12b5, #aa16c1, #921fce, #881ed5, #7d1fdc, #6e21e3, #5c24eb);
}